import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAlert } from '../hooks/useAlert';
import Alert from '../components/Alert';

export default function OuterLayout() {
  const { alerts } = useAlert();
  return (
    <div className="w--full  h--full d--flex align-items--center justify-content--center loginPage bg--contrast">
      <div className="radius--md  gap--xl w-max--600 w--full bg--white p--xl z-index--xs bg--white box-shadow d--flex align-items--center justify-content--center flex--column gap--xs h-min--300">
        <Outlet />
      </div>

      <div className="main-alert position--absolute d--flex flex--column gap--sm">
      {/* if alert array contains something then show the alert */}
        {alerts?.map((alert) => (
          <Alert key={alert?.id} alert={alert} />
        ))}
      </div>
    </div>
  );
}
