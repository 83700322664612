import React from 'react';
import Button from '../Button';

const Tabbing = ({ tab, setTab = () => null, tabsLabels = [] }) => {
  if (!tabsLabels.length) return null;
  return (
    <div className="w--full d--flex align-items--center gap--sm justify-content--center w-max--300  bg--white radius--sm h-max--36">
      {tabsLabels.map((label) => {
        return (
          <Button
            key={label?.value}
            variant={`${tab === label?.value ? 'secondary btn' : 'white btn'}`}
            color={`${tab === label?.value ? 'white' : 'primary'}`}
            onClick={() => setTab(label?.value)}
            disabled={label?.isDisabled ?? false}
          >
            {label?.label}
          </Button>
        );
      })}
    </div>
  );
};

export default Tabbing;
