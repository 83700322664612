import React, { useEffect, useState } from 'react';
import FormInput from '../../../components/FormInput';
import FormSelect from '../../../components/FormSelect';
import Button from '../../../components/Button';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useCountryList from '../../../hooks/useCountryList';
// import PhoneInput from 'react-phone-number-input';
import PhoneInput from "react-phone-number-input/react-hook-form-input"
import useAddUser from '../../../hooks/useAddUser';
import useUpdateUser from '../../../hooks/useUpdateUser';
import useGetPlans from '../../../hooks/useGetPlans';
import { Country } from 'country-state-city';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  password: '',
  // timezone: '',
  // agent_company: '',
  // agent_npn: '',
  // country: '',
  // agency_name: '',
  // agency_email: '',
  // agency_phone: '',
  // agency_npn: '',
  // agency_address: '',
};

const countriesList = Country.getAllCountries().map((country) => {
  const { name, isoCode } = country;
  return { label: name, value: isoCode };
});
const RenderCountry = ({ item }) => {
  return (
    <option key={item.value} value={item.value}>
      {item.label}
    </option>
  );
};

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('First name is required')
    .matches(/^[A-Za-z'-]+[ A-Za-z'-]*$/, 'First name cannot contain numbers'),
  lastName: yup
    .string()
    .required('Last name is required')
    .matches(/^[A-Za-z'-]+[ A-Za-z'-]*$/, 'Last name cannot contain numbers'),
  email: yup.string().email('Email is invalid').required('Email is required'),
  phone: yup.string().required('Phone Number is required').nullable(),
  password: yup
    .string()
    .required('Password field is required.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
    ),
  // timezone: yup.string().required('Timezone is required'),
  // country: yup.string().required('Country is required'),
  // agent_company: yup
  //   .string()
  //   .required('Agent company is required')
  //   .matches(
  //     /^[A-Za-z'-]+[ A-Za-z'-]*$/,
  //     'Agent company cannot contain numbers',
  //   ),
  // agent_npn: yup
  //   .string()
  //   .required('Agent npn is required')
  //   .matches(/^[0-9]+$/, 'Agent npn must contain only  numbers'),
  // agency_name: yup
  //   .string()
  //   .required('First name is required')
  //   .matches(/^[A-Za-z'-]+[ A-Za-z'-]*$/, 'First name cannot contain numbers'),
  // agency_email: yup
  //   .string()
  //   .email('Email is invalid')
  //   .required('Email is required'),
  // agency_phone: yup.string().required('Phone Number is required'),
  // agency_npn: yup
  //   .string()
  //   .required('Agent npn is required')
  //   .matches(/^[0-9]+$/, 'Agent npn must contain only  numbers'),
  // agency_address: yup.string().required('Phone Number is required'),
});

const RenderTimeZoneItem = ({ item }) => {
  return (
    <option key={item.abbreviation} value={item.abbreviation}>
      {item.zoneName}
    </option>
  );
};
const RenderPlanItem = ({ item }) => {
  return (
    <option key={item.uuid} value={item.uuid}>
      {item.plan_name}
    </option>
  );
};


// -------------------------------------------------
export default function AddUser({
  editUserInstance = {},
  handleClose = () => null,
}) {
  const countries = useCountryList();
  // const { mutateAsync, data } = useGetPlans();
  const [hasAgency , setHasAgency] = useState(false)

  const {
    watch,
    getValues,
    setValue,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  // useEffect(() => {
  //   mutateAsync();
  // }, []);

  const { mutate: mutateAdd, isLoading: loadAdd } = useAddUser(handleClose);
  const { mutate: mutateUpdate, isLoading: loadUpdate } =
    useUpdateUser(handleClose);

  useEffect(() => {
    const { isEdit = false, editData = {} } = editUserInstance;
    if (
      isEdit &&
      editData &&
      Object.keys(editData) &&
      Object.keys(editData).length > 0
    ) {
      reset(editData);
    }
  }, []);

  async function onSubmit(values) {
    const { isEdit = false, editData = {} } = editUserInstance;

    if (isEdit) {
      values.uuid = editData?.uuid;
      // mutateUpdate(values);
    } else {
      mutateAdd(values);
    }
  }

  // const selectedCountry = watch('country');

  // const timezones = selectedCountry
  //   ? countries?.find((country) => country.isoCode === selectedCountry)
  //       ?.timezones
  //   : [];

  const loading = isSubmitting || loadAdd || loadUpdate;
  return (
    <form
      className="w--full d--flex flex--column gap--md"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <div className="overflow--auto dialogScroll w--full d--flex flex--column gap--md p--md">
        <div className="font--md font--600">Your Information</div>
        <div className="d--flex gap--md">
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="input"
                label="First Name"
                placeholder="Enter first name"
                error={errors?.firstName?.message}
              />
            )}
          />
        </div>
        <div className="d--flex gap--md">
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="input"
                label="Last Name"
                placeholder="Enter last name"
                error={errors?.lastName?.message}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="input"
                label="Email"
                placeholder="Enter email address"
                error={errors?.email?.message}
              />
            )}
          />
        </div>
        <div>
          <label
            className={`label--control font--sm font--500 m-b--xs ${
              errors?.phone?.message ? 'text--danger' : 'text--black'
            }`}
          >
            {errors?.phone?.message ?? 'Phone Number'}
          </label>

          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <PhoneInput
                {...field}
                control={control}
                // defaultCountry={watch('country')}
                // international={true}
                // addInternationalOption={false}
                // withCountryCallingCode={true}
                // countryCallingCodeEditable={false}
                placeholder="Enter your phone"
                // internationalIcon={() => null}
                // flagComponent={() => null}
                // countrySelectComponent={() => null}
                value={getValues(`phone`)}
                // limitMaxLength={true}
                onChange={(phone) => setValue(`phone`, phone)}
                className={`form--control w--full h-min--36  radius--sm p-l--md p-r--md  ${
                  errors?.phone?.message
                    ? 'border-full--danger'
                    : 'border-full--black-200'
                }`}
              />
            )}
          />
        </div>

        <div>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="input"
                label="Password"
                placeholder="Enter password"
                error={errors?.password?.message}
              />
            )}
          />
        </div>

        {/* <div>
          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <FormSelect
                {...field}
                options={countriesList || []}
                renderOption={RenderCountry}
                placeholder="Select"
                label="Country"
                error={errors?.country?.message}
              />
            )}
          />
        </div> */}

        {/* <div>
          <Controller
            name="timezone"
            control={control}
            render={({ field }) => (
              <FormSelect
                {...field}
                options={timezones || []}
                renderOption={RenderTimeZoneItem}
                placeholder="Select"
                label="Timezone"
                error={errors?.timezone?.message}
              />
            )}
          />
        </div> */}
        {/* <div>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="password"
                label="Password"
                placeholder="Enter your password"
                error={errors?.password?.message}
              />
            )}
          />
        </div> */}
        {/* <div>
          <Controller
            name="agent_company"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="input"
                label="Agent Company"
                placeholder="Enter agent company"
                error={errors?.agent_company?.message}
              />
            )}
          />
        </div> */}
        {/* <div>
          <Controller
            name="agent_npn"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="input"
                label="NPN"
                placeholder="Enter NPN"
                error={errors?.agent_npn?.message}
              />
            )}
          />
        </div> */}
        {/* ------------------------------------------------------------ */}
        {/* <div className='tw-flex tw-gap-x-4 tw-text-xl tw-font-normal'>
          <p>Do you own Agency</p>
          <div className='tw-flex tw-gap-x-2'>
            <input type="radio" name="agencyInfo" id="yes" onClick={()=>{setHasAgency(true)} }/>
            <label htmlFor="yes">Yes</label>
          </div>
          <div className='tw-flex tw-gap-x-2'>
            <input type="radio" name="agencyInfo" id="no" onClick={()=>{hasAgency ==true ?setHasAgency(false) : ''}} defaultChecked={true}/>
            <label htmlFor="yes">No</label>
          </div>
        </div> */}

        {/* {hasAgency  &&  <><div className="font--md font--600">Your Agency Information</div>
        <div className="d--flex gap--md">
          <Controller
            name="agency_name"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="input"
                label="Agency Name"
                placeholder="Agency name"
                error={errors?.agency_name?.message}
              />
            )}
          />
        </div>

        <div>
          <Controller
            name="agency_email"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="input"
                label="Agency Email"
                placeholder="Enter agency email address"
                error={errors?.agency_email?.message}
              />
            )}
          />
        </div>

        <div>
          <label
            className={`label--control font--sm font--500 m-b--xs ${
              errors?.agency_phone?.message ? 'text--danger' : 'text--black'
            }`}
          >
            {errors?.agency_phone?.message ?? 'Agency Phone'}
          </label>

          <Controller
            name="agency_phone"
            control={control}
            render={({ field }) => (
              <PhoneInput
                {...field}
                control={control}
                defaultCountry={watch('country')}
                international={true}
                addInternationalOption={false}
                withCountryCallingCode={true}
                countryCallingCodeEditable={false}
                placeholder="Enter your phone"
                internationalIcon={() => null}
                flagComponent={() => null}
                countrySelectComponent={() => null}
                value={getValues(`agency_phone`)}
                limitMaxLength={true}
                onChange={(phone) => setValue(`agency_phone`, agency_phone)}
                className={`form--control w--full h-min--36  radius--sm p-l--md p-r--md  ${
                  errors?.agency_phone?.message
                    ? 'border-full--danger'
                    : 'border-full--black-200'
                }`}
              />
            )}
          />
        </div>

        <div>
          <Controller
            name="agency_npn"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="input"
                label="Agency NPN"
                placeholder="Enter agency NPN"
                error={errors?.agency_npn?.message}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="agency_address"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="input"
                label="Agency Adress"
                placeholder="Enter agency address"
                error={errors?.agency_address?.message}
              />
            )}
          />
        </div></>} */}
       
      </div>
      <div className="d--flex justify-content--between gap--md p-l--md p-r--md">
        <Button
          type="button"
          variant="contrast"
          color="primary"
          btnClasses="btn"
          onClick={() => handleClose()}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          size=""
          variant="primary"
          color="white"
          btnClasses="btn"
          disabled={loading}
        >
          {loading ? 'Please wait..' : 'Save'}
        </Button>
      </div>
    </form>
  );
}
