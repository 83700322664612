import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import TableManager from '../../../../../components/TableManager';
import MultiDropDown from '../../../../../components/MultiDropdown';
import { getAgentMembers } from '../../../../../api';
import { memberAgentslabelsArray } from '../../../../../helpers/functions';
import Button from '../../../../../components/Button';
import AddMemberForm from '../AddMemberForm';

import AsideModal from '../../../../../components/AsideModal';
import useIcons from '../../../../../assets/icons/useIcons';
import Dropdown from '../../../../../components/Dropdown';
import ConfirmComponent from '../../../../../components/ConfirmComponent';
import useDeleteUserEmployee from '../../../../../hooks/useDeleteUserEmployee';
const initialEditInstance = { isEdit: false, editData: {} };

const Member = ({ tab, setTab }) => {
  const { id } = useParams();
  const [addMember, setAddMember] = useState(false);
  const [editMemberInstance, setEditMemberInstance] =
    useState(initialEditInstance);
  const { MoreVIcon } = useIcons();
  const { mutate: memberDeleteMutate } = useDeleteUserEmployee();
  const handleDropListClick = ({ value }, data) => {
    switch (value) {
      case 'edit':
        setEditMemberInstance({ isEdit: true, editData: data });
        setAddMember(true);
        break;

      default:
        break;
    }
  };

  const columns = [
    {
      accessorKey: 'first_name',
      header: () => 'First Name',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'last_name',
      header: () => 'Last Name',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'email',
      header: () => 'Email',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'phone',
      header: () => 'Phone',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'extension',
      header: () => 'Extension',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'assigned_did_array',
      header: () => 'Virtual Number',
      cell: (props) => {
        const element = props?.row?.original || {};
        const { assigned_did_array = [], assigned_did = [] } = element;
        return (
          <>
            {assigned_did_array?.length === 0 ? (
              '---'
            ) : (
              <div className="w-max--120">
                <MultiDropDown
                  labelKey={'caller_id'}
                  valueKey={'caller_id'}
                  value={assigned_did_array ?? []}
                  placeholder={`${assigned_did_array?.length} number(s)`}
                  readOnly={true}
                  optionList={assigned_did || []}
                  withCaret={true}
                  height="28"
                  paddingRight="xs"
                  paddingLeft="xs"
                  extraClasses="no--focus link"
                  isSearchable={false}
                />
              </div>
            )}
          </>
        );
      },
    },
    {
      accessorKey: 'is_active',
      header: () => 'Status',
      cell: (props) => {
        if (props?.getValue() === 1) {
          return 'Activated';
        } else {
          return 'Deactivated';
        }
      },
      meta: {
        textAlign: 'center',
      },
    },
    // {
    //   accessorKey: 'action',
    //   header: () => 'Action',
    //   cell: (props) => {
    //     const element = props?.row?.original;
    //     return (
    //       <div className="gap--xs w--full d--flex align-items--center justify-content--center">
    //         <div className="d--flex gap--xs">
    //           <Dropdown
    //             extraClasses="w-min--150"
    //             closeOnClickOutside={false}
    //             dropList={{
    //               component: ({ item }) => {
    //                 if (item?.shouldConfirm) {
    //                   return (
    //                     <ConfirmComponent
    //                       element={element}
    //                       label="Please confirm?"
    //                       confirmCallback={(data) => handlMemberDelete(data)}
    //                     >
    //                       {item?.title}
    //                     </ConfirmComponent>
    //                   );
    //                 }
    //                 return (
    //                   <span
    //                     className="w--full d--flex  justify-content--start p--2sm"
    //                     onClick={() => handleDropListClick(item, element)}
    //                   >
    //                     {item?.title}
    //                   </span>
    //                 );
    //               },
    //               data: [
    //                 { title: 'Edit', value: 'edit' },
    //                 {
    //                   title: 'Delete',
    //                   value: 'delete',
    //                   shouldConfirm: true,
    //                 },
    //               ],
    //             }}
    //             showcaret={false}
    //           >
    //             <div className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm bg--black-100 text--black  c--pointer">
    //               <MoreVIcon width={14} height={14} />
    //             </div>
    //           </Dropdown>
    //         </div>
    //       </div>
    //     );
    //   },
    //   meta: {
    //     colType: 'action',
    //     textAlign: 'center',
    //     width: '5%',
    //   },
    // },
  ];

  const tableActions = [
    {
      id: 1,
      label: 'Add Employee',
      access: false,
      component: (
        <Button
          type="button"
          btnClasses="btn w-min--150"
          variant="primary"
          color="white"
          onClick={() => setAddMember(true)}
        >
          Add Employee
        </Button>
      ),
    },
  ];

  const handleClose = () => {
    setEditMemberInstance({ isEdit: false, editData: {} });
    setAddMember(false);
  };

  const handlMemberDelete = (data) => {
    const { uuid = '' } = data;
    memberDeleteMutate({ uuid });
  };

  return (
    <>
      <div className="w--full userDetailsCommon">
        <TableManager
          {...{
            extraClasses: 'table--responsive--full',
            name: 'Employees',
            columns,
            fetcherFn: getAgentMembers,
            fetcherKey: 'getAgentMembers',
            tableActions,
            withTabs: true,
            tab,
            setTab,
            extraParams: {
              uuid: id,
            },
            tabsLabels: memberAgentslabelsArray,
          }}
        />
      </div>
      {addMember && (
        <AsideModal
          handleClose={handleClose}
          title={
            editMemberInstance?.isEdit ? 'Update Employee' : 'Add Employee'
          }
          footerComponent={null}
        >
          <AddMemberForm
            editMemberInstance={editMemberInstance}
            handleClose={handleClose}
          />
        </AsideModal>
      )}
    </>
  );
};

export default Member;
