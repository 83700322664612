// import React, { useLayoutEffect, useRef, useState } from 'react';
// import useClickOutside from '../../hooks/useClickOutside';

// const Dropdown = ({
//   children,
//   dropList = {},
//   closeOnClickOutside = false,
//   caretComponent: CaretComponent = null,
//   showcaret = false,
//   header: Header = null,
//   footer: Footer = null,
//   extraClass = '',
// }) => {
//   const [show, setShow] = useState(false);
//   const [shouldShowStyle, setShouldSetStyle] = useState(false);
//   const elRef = useRef(null);
//   useClickOutside([elRef], () => {
//     setShow(false);
//   });

//   useLayoutEffect(() => {
//     let element = document.getElementById('dropdown-menu-elm');
//     if (element) {
//       if (
//         window.innerHeight - element.getBoundingClientRect().bottom >=
//         element.scrollHeight
//       ) {
//         setShouldSetStyle(false);
//       } else {
//         setShouldSetStyle(true);
//       }
//     }
//   }, [show]);
//   return (
//     <dropdown-element
//       dropdown-element
//       ref={elRef}
//       onClick={(e) => {
//         e.preventDefault();
//         if (closeOnClickOutside) {
//           setShow((prev) => !prev);
//         } else {
//           setShow(true);
//         }
//       }}
//     >
//       {children}

//       {showcaret && (
//         <dropdown-caret dropdown-caret>
//           <CaretComponent />
//         </dropdown-caret>
//       )}

//       {show && (
//         <dropdown-menu
//           id="dropdown-menu-elm"
//           dropdown-menu
//           class={`${extraClass} ${
//             shouldShowStyle ? 'bottom--100' : 'top--100'
//           }`}
//         >
//           {Header ? (
//             <dropdown-header>
//               <Header />
//             </dropdown-header>
//           ) : null}
//           <dropdown-menu-body dropdown-menu-body>
//             {dropList?.data?.length > 0 ? (
//               dropList.data.map((item) => {
//                 return (
//                   <dropdown-menu-item dropdown-menu-item key={item.id}>
//                     <dropList.component item={item} />
//                   </dropdown-menu-item>
//                 );
//               })
//             ) : (
//               <span>No record</span>
//             )}
//           </dropdown-menu-body>
//           {Footer ? (
//             <dropdown-footer>
//               <Footer />
//             </dropdown-footer>
//           ) : null}
//         </dropdown-menu>
//       )}
//     </dropdown-element>
//   );
// };

// export default Dropdown;

import React, { useLayoutEffect, useRef, useState } from 'react';
import useClickOutside from '../../hooks/useClickOutside';

const Dropdown = ({
  children,
  dropList = {},
  closeOnClickOutside = false,
  caretComponent: CaretComponent = null,
  showcaret = false,
  header: Header = null,
  footer: Footer = null,
  extraClasses = '',
}) => {
  const [show, setShow] = useState(false);
  const [shouldShowStyle, setShouldSetStyle] = useState(false);
  const elRef = useRef(null);
  useClickOutside([elRef], () => {
    setShow(false);
  });
  useLayoutEffect(() => {
    let element = document.getElementById('dropdown-menu-elm');
    if (element) {
      if (
        window.innerHeight - element.getBoundingClientRect().bottom >=
        element.scrollHeight
      ) {
        setShouldSetStyle(false);
      } else {
        setShouldSetStyle(true);
      }
    }
  }, [show]);
  return (
    <dropdown-element
      dropdown-element
      ref={elRef}
      onClick={(e) => {
        e.preventDefault();
        if (closeOnClickOutside) {
          setShow((prev) => !prev);
        } else {
          setShow(true);
        }
      }}
    >
      {children}

      {showcaret && (
        <dropdown-caret dropdown-caret>
          <CaretComponent />
        </dropdown-caret>
      )}

      {show && (
        <dropdown-menu
          id="dropdown-menu-elm"
          dropdown-menu
          class={`${extraClasses} ${
            shouldShowStyle ? 'bottom--100' : 'top--100'
          }`}
        >
          {Header ? (
            <dropdown-header>
              <Header />
            </dropdown-header>
          ) : null}
          <dropdown-menu-body dropdown-menu-body>
            {dropList?.data?.length > 0 ? (
              dropList.data.map((item) => {
                return (
                  <dropdown-menu-item dropdown-menu-item key={item.title}>
                    <dropList.component item={item} />
                  </dropdown-menu-item>
                );
              })
            ) : (
              <span>No record</span>
            )}
          </dropdown-menu-body>
          {Footer ? (
            <dropdown-footer>
              <Footer />
            </dropdown-footer>
          ) : null}
        </dropdown-menu>
      )}
    </dropdown-element>
  );
};

export default Dropdown;
