import React, { useEffect } from 'react';
import FormInput from '../../../components/FormInput';
import FormSelect from '../../../components/FormSelect';
import Button from '../../../components/Button';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useCountryList from '../../../hooks/useCountryList';
import PhoneInput from 'react-phone-number-input';
// import PhoneInput from "react-phone-number-input/react-hook-form-input"
import useAddCarrier from '../../../hooks/useAddCarrier';
import useUpdateCarrier from '../../../hooks/useUpdateCarrier';
import useGetPlans from '../../../hooks/useGetPlans';
import { Country } from 'country-state-city';

const initialValues = {
  
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  phone: ''
};

const countriesList = Country.getAllCountries().map((country) => {
  const { name, isoCode } = country;
  return { label: name, value: isoCode };
});

const validationSchema = yup.object().shape({
   firstName: yup
    .string()
    .required('First name is required')
    .matches(/^[A-Za-z'-]+[ A-Za-z'-]*$/, 'First name cannot contain numbers'),
  lastName: yup
    .string()
    .required('Last name is required')
    .matches(/^[A-Za-z'-]+[ A-Za-z'-]*$/, 'Last name cannot contain numbers'),
  email: yup.string().email('Email is invalid').required('Email is required'),
  phone: yup.string().required('Phone Number is required').nullable(),
  password: yup.string().required('Password is required'),

  });

const RenderTimeZoneItem = ({ item }) => {
  return (
    <option key={item.abbreviation} value={item.abbreviation}>
      {item.zoneName}
    </option>
  );
};
const RenderCountry = ({ item }) => {
  return (
    <option key={item.value} value={item.value}>
      {item.label}
    </option>
  );
};
const RenderPlanItem = ({ item }) => {
  return (
    <option key={item.uuid} value={item.uuid}>
      {item.plan_name}
    </option>
  );
};
export default function AddCarrier({
  editCarrierInstance = {},
  handleClose = () => null,
}) {
  const countries = useCountryList();
  // const { mutateAsync, data } = useGetPlans();
  const {
    watch,
    getValues,
    setValue,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  // useEffect(() => {
  //   mutateAsync();
  // }, []);

  const { mutate: mutateAdd, isLoading: loadAdd } = useAddCarrier(handleClose);
  const { mutate: mutateUpdate, isLoading: loadUpdate } =
    useUpdateCarrier(handleClose);

  useEffect(() => {
    const { isEdit = false, editData = {} } = editCarrierInstance;
    if (
      isEdit &&
      editData &&
      Object.keys(editData) &&
      Object.keys(editData).length > 0
    ) {
      reset(editData);
    }
  }, []);

  async function onSubmit(values) {
    const { isEdit = false, editData = {} } = editCarrierInstance;

    if (isEdit) {
      values.uuid = editData?.uuid;
      // mutateUpdate(values);
    } else {
      mutateAdd(values);
    }
  }

  // const selectedCountry = watch('country');

  // const timezones = selectedCountry
  //   ? countries?.find((country) => country.isoCode === selectedCountry)
  //       ?.timezones
  //   : [];

  const loading = isSubmitting || loadAdd || loadUpdate;
  return (
    <form
      className="w--full d--flex flex--column gap--md"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <div className="overflow--auto dialogScroll w--full d--flex flex--column gap--md p--md">
              
        
        <div className="d--flex gap--md">
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="input"
                label="First Name"
                placeholder="Enter first name"
                error={errors?.firstName?.message}
              />
            )}
          />
        </div>
        <div className="d--flex gap--md">
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="input"
                label="Last Name"
                placeholder="Enter last name"
                error={errors?.lastName?.message}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="input"
                label="Email"
                placeholder="Enter email address"
                error={errors?.email?.message}
              />
            )}
          />
        </div>

        <div>
          <label
            className={`label--control font--sm font--500 m-b--xs ${
              errors?.phone?.message ? 'text--danger' : 'text--black'
            }`}
          >
            {errors?.phone?.message ?? 'Phone Number'}
          </label>

          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <PhoneInput
                {...field}
                control={control}
                // defaultCountry={watch('country')}
                international={true}
                addinternationaloption={false}
                withCountryCallingCode={true}
                countryCallingCodeEditable={true}
                placeholder="Enter phone number"
                internationalIcon={() => null}
                // flagComponent={() => null}
                countrySelectComponent={() => null}
                value={getValues(`phone`)}
                limitMaxLength={true}
                onChange={(phone) => setValue(`phone`, phone)}
                className={`form--control w--full h-min--36  radius--sm p-l--md p-r--md  ${
                  errors?.phone?.message
                    ? 'border-full--danger'
                    : 'border-full--black-200'
                }`}
              />
            )}
          />
        </div>

        <div>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="input"
                label="Password"
                placeholder="Enter password"
                error={errors?.password?.message}
              />
            )}
          />
        </div>

      
        {/* ------------------------------------------------------------------ */}
        {/* <div className="d--flex gap--md">
          <div className="w--full">
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <FormSelect
                  {...field}
                  options={countriesList || []}
                  renderOption={RenderCountry}
                  placeholder="Select"
                  label="Country"
                  error={errors?.country?.message}
                />
              )}
            />
          </div>

          <div className="w--full">
            <Controller
              name="state"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="text"
                  label="State"
                  placeholder="Enter your state"
                  maxLength={30}
                  error={errors?.state?.message}
                />
              )}
            />
          </div>
        </div> */}
        {/* ----------------------------------------------------------------------------------------- */}

        {/* ------------------------------------------------------------------------- */}
        {/* <div className="d--flex gap--md">
          <div className="w--full">
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="text"
                  label="City"
                  placeholder="Enter your city"
                  maxLength={30}
                  error={errors?.city?.message}
                />
              )}
            />
          </div>
          <div className="w--full">
            <Controller
              name="postalCode"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="text"
                  label="Postal Code"
                  placeholder="Enter your postal code"
                  maxLength={30}
                  error={errors?.postalCode?.message}
                />
              )}
            />
          </div>
        </div> */}
        {/* -------------------------------------------------------------------------- */}

      </div>
      <div className="d--flex justify-content--between gap--md p-l--md p-r--md">
        <Button
          type="button"
          variant="contrast"
          color="primary"
          btnClasses="btn"
          onClick={() => handleClose()}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          size=""
          variant="primary"
          color="white"
          btnClasses="btn"
          disabled={loading}
        >
          {loading ? 'Please wait..' : 'Save'}
        </Button>
      </div>
    </form>
  );
}
