import React, { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Error500Page from '../pages/Error500Page';
import Login from '../pages/Login';
import Users from '../pages/Users';
import Agencies from '../pages/Agency';
import Consumer from '../pages/Consumer'
import PageNotFound from '../pages/PageNotFound';
import OuterLayout from '../layouts/OuterLayout';
// import Dashboard from '../pages/Dashboard';
import UserDetails from '../pages/UserDetails';

import Profile from '../pages/UserDetails/Contents/Profile';
import Agency from '../pages/UserDetails/Contents/Agency';
import MemberAgents from '../pages/UserDetails/Contents/MemberAgents';
import DIDNumber from '../pages/UserDetails/Contents/DIDNumber';
import Call from '../pages/UserDetails/Contents/Call';
import DIDBilling from '../pages/UserDetails/Contents/DIDBilling';
import PlanBilling from '../pages/UserDetails/Contents/PlanBilling';

import UserInfoLayout from '../layouts/UserInfoLayout';
import Carrier from '../pages/Carrier';
import Documents from '../pages/Documents';
import Claim from '../pages/Claim';

const AuthRemover = lazy(() => import('../auth/AuthRemover'));
const AuthProvider = lazy(() => import('../auth/AuthProvider'));
const AuthLayout = lazy(() => import('../layouts/AuthLayout'));

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AuthProvider>
        <AuthLayout />
      </AuthProvider>
    ),
    errorElement: <Error500Page />,
    children: [
      {
        path: 'user',
        element: <Users />,
        id: 'Agent',
      },
      // {
      //   path: 'dashboard',
      //   element: <Dashboard />,
      //   id: 'Dashboard',
      // },
      {
        path: 'agency',
        element: <Agencies />,
        id: 'Agency',
      },
      {
        path: 'consumer',
        element: <Consumer/>,
        id: 'Consumer',
      },
      {
        path: 'carrier',
        element: <Carrier />,
        id: 'Carrier',
      },
      {
        path: 'documents',
        element: <Documents />,
        id: 'Documents',
      },
      {
        path: 'claim',
        element: <Claim />,
        id: 'Claim',
      },
      
    ],
  },
  {
    path: 'user-info',
    element: (
      <AuthProvider>
        <UserInfoLayout />
      </AuthProvider>
    ),
    errorElement: <Error500Page />,
    children: [
      {
        path: ':id',
        element: <UserDetails />,
        children: [
          {
            index: true,
            path: 'profile',
            element: <Profile />,
          },
          {
            path: 'member',
            element: <MemberAgents />,
          },
          {
            path: 'agency',
            element: <Agency />,
          },
          {
            path: 'did-number',
            element: <DIDNumber />,
          },
          {
            path: 'call',
            element: <Call />,
          },
          {
            path: 'did-billing',
            element: <DIDBilling />,
          },
          {
            path: 'plan-billing',
            element: <PlanBilling />,
          },
        ],
      },
    ],
  },
  {
    path: '/',
    element: (
      <AuthRemover>
        <OuterLayout />
      </AuthRemover>
    ),
    errorElement: <Error500Page />,
    children: [
      {
        index: true,
        element: <Login />,
        id: 'Login',
      },
    ],
  },

  {
    path: '*',
    element: <PageNotFound />,
  },
]);
