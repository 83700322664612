import React, { useState } from 'react';
import TableManager from '../../../../../components/TableManager';
import { getAgentAgents } from '../../../../../api';
import { useParams } from 'react-router-dom';
import { memberAgentslabelsArray } from '../../../../../helpers/functions';
import AsideModal from '../../../../../components/AsideModal';
import AddAgentsForm from '../AddAgentsForm';
import Button from '../../../../../components/Button';
import useIcons from '../../../../../assets/icons/useIcons';
import Dropdown from '../../../../../components/Dropdown';
import ConfirmComponent from '../../../../../components/ConfirmComponent';
import useDeleteUserAgent from '../../../../../hooks/useDeleteUserAgent';

const initialEditInstance = { isEdit: false, editData: {} };

const Agents = ({ tab, setTab }) => {
  const { id } = useParams();
  const { MoreVIcon } = useIcons();
  const [addAgent, setAddAgent] = useState(false);
  const { mutate: memberDeleteAgent } = useDeleteUserAgent();
  const [editMemberInstance, setEditMemberInstance] =
    useState(initialEditInstance);

  const handleDropListClick = ({ value }, data) => {
    switch (value) {
      case 'edit':
        setEditMemberInstance({ isEdit: true, editData: data });
        setAddAgent(true);
        break;

      default:
        break;
    }
  };

  const tableActions = [
    {
      id: 1,
      label: 'Add Agent',
      access: false,
      component: (
        <Button
          type="button"
          btnClasses="btn w-min--150"
          variant="primary"
          color="white"
          onClick={() => setAddAgent(true)}
        >
          Add Agent
        </Button>
      ),
    },
  ];
  const columns = [
    {
      accessorKey: 'first_name',
      header: () => 'First Name',
    },
    {
      accessorKey: 'email',
      header: () => 'Email',
    },
    {
      accessorKey: 'extension',
      header: () => 'Extension',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'phone',
      header: () => 'Phone',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'is_active',
      header: () => 'Status',
      cell: (props) => {
        if (props?.getValue() === 1) {
          return 'Activated';
        } else {
          return 'Deactivated';
        }
      },
      meta: {
        textAlign: 'center',
      },
    },
    // {
    //   accessorKey: 'action',
    //   header: () => 'Action',
    //   cell: (props) => {
    //     const element = props?.row?.original;
    //     return (
    //       <div className=" w--full ">
    //         <div className="d--flex align-items--center justify-content--center gap--xs">
    //           <Dropdown
    //             extraClasses="w-min--150"
    //             closeOnClickOutside={false}
    //             dropList={{
    //               component: ({ item }) => {
    //                 if (item?.shouldConfirm) {
    //                   return (
    //                     <ConfirmComponent
    //                       item={item}
    //                       element={element}
    //                       label="Please confirm?"
    //                       confirmCallback={(data) => handleAgentDelete(data)}
    //                     >
    //                       {item?.title}
    //                     </ConfirmComponent>
    //                   );
    //                 }
    //                 return (
    //                   <span
    //                     className="w--full d--flex  justify-content--start p--2sm"
    //                     onClick={() => handleDropListClick(item, element)}
    //                   >
    //                     {item?.title}
    //                   </span>
    //                 );
    //               },
    //               data: [
    //                 { title: 'Edit', value: 'edit' },
    //                 {
    //                   title: 'Delete',
    //                   value: 'delete',
    //                   shouldConfirm: true,
    //                 },
    //               ],
    //             }}
    //             showcaret={false}
    //           >
    //             <div className="w-min--28 h-min--28 w-max--28 d--flex align-items--center justify-content--center radius--sm bg--black-100 text--black  c--pointer">
    //               <MoreVIcon width={14} height={14} />
    //             </div>
    //           </Dropdown>
    //         </div>
    //       </div>
    //     );
    //   },
    //   meta: {
    //     colType: 'action',
    //     textAlign: 'center',
    //     width: '5%',
    //   },
    // },
  ];
  const handleClose = () => {
    setEditMemberInstance({ isEdit: false, editData: {} });
    setAddAgent(false);
  };

  const handleAgentDelete = (data) => {
    const { uuid = '' } = data;
    memberDeleteAgent({ uuid });
  };

  return (
    <div className="w--full userDetailsCommon">
      <TableManager
        {...{
          name: 'Agents',
          columns,
          fetcherFn: getAgentAgents,
          fetcherKey: 'getAgentAgents',
          extraClasses: 'table--responsive--full',
          tableActions,
          extraParams: {
            uuid: id,
          },
          withTabs: true,
          tab,
          setTab,
          tabsLabels: memberAgentslabelsArray,
        }}
      />
      {addAgent && (
        <AsideModal
          handleClose={handleClose}
          title={editMemberInstance?.isEdit ? 'Update Agent' : 'Add Agent'}
          footerComponent={null}
        >
          <AddAgentsForm
            editMemberInstance={editMemberInstance}
            handleClose={handleClose}
          />
        </AsideModal>
      )}
    </div>
  );
};

export default Agents;
