import React, { useState, useContext, createContext, useMemo } from 'react';
const AuthContext = createContext(null);

export const Auth = ({ children }) => {
  const [user, setCurrentUser] = useState(null);

  const setUser = (data) => {
    setCurrentUser({ token: data.token });
    localStorage.setItem('nph-admin-acc-tkn', data.token);
  };

  const clearUser = () => {
   
    // localStorage.clear();
    localStorage.removeItem('nph-admin-acc-tkn');
    localStorage.removeItem('konshura-acc-tkn');
    setCurrentUser(null);
    window.location.href = '/';
  };

  const setUserData = (payload) => {
    setCurrentUser((prev) => ({ ...prev, userDetails: payload }));
  };

  const values = useMemo(
    () => ({ user, setUser, clearUser, setUserData }),
    [user],
  );
  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
