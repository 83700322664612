import React, { useEffect , useState } from 'react';
import FormInput from '../../../components/FormInput';
import FormSelect from '../../../components/FormSelect';
import Button from '../../../components/Button';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useCountryList from '../../../hooks/useCountryList';
import PhoneInput from 'react-phone-number-input';
import useAddUser from '../../../hooks/useAddUser';
import useUpdateUser from '../../../hooks/useUpdateUser';
import useGetPlans from '../../../hooks/useGetPlans';
import { Country } from 'country-state-city';

const initialValues = {
  agency_name: '',
  agency_email: '',
  agency_phone: '',
  agency_npn: '',

  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  country: 'US',
  state: '',
  city: '',
  postalCode: '',
};

const countriesList = Country.getAllCountries().map((country) => {
  const { name, isoCode } = country;
  return { label: name, value: isoCode };
});

const validationSchema = yup.object().shape({
  agency_name: yup
    .string()
    .required('Agency name is required')
    .matches(/^[A-Za-z'-]+[ A-Za-z'-]*$/, 'Agency name cannot contain numbers'),
  agency_email: yup
    .string()
    .email('Agency Email is invalid')
    .required('Agency Email is required'),
  agency_phone: yup.string().required('Agency Phone Number is required'),
  agency_npn: yup
    .string()
    .required('Agency npn is required')
    .matches(/^[0-9]+$/, 'Agency npn must contain only  numbers'),
  first_name: yup
    .string()
    .required('First name is required')
    .matches(/^[A-Za-z'-]+[ A-Za-z'-]*$/, 'First name cannot contain numbers'),
  last_name: yup
    .string()
    .required('Last name is required')
    .matches(/^[A-Za-z'-]+[ A-Za-z'-]*$/, 'Last name cannot contain numbers'),
  email: yup.string().email('Email is invalid').required('Email is required'),
  phone: yup.string().required('Phone Number is required'),

  country: yup.string().required('Country is required'),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  postalCode: yup.string().required('Postal code is required'),
});

const RenderTimeZoneItem = ({ item }) => {
  return (
    <option key={item.abbreviation} value={item.abbreviation}>
      {item.zoneName}
    </option>
  );
};
const RenderCountry = ({ item }) => {
  return (
    <option key={item.value} value={item.value}>
      {item.label}
    </option>
  );
};
const RenderPlanItem = ({ item }) => {
  return (
    <option key={item.uuid} value={item.uuid}>
      {item.plan_name}
    </option>
  );
};
// ----------------------------------------------------------

export default function AddDocument({
  editUserInstance = {},
  handleClose = () => null,
}) {
  const countries = useCountryList();
  // const { mutateAsync, data } = useGetPlans();
  const [selectedFile, setSelectedFile] = useState("No file chosen");

  const {
    watch,
    getValues,
    setValue,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  // useEffect(() => {
  //   mutateAsync();
  // }, []);

  const { mutate: mutateAdd, isLoading: loadAdd } = useAddUser(handleClose);
  const { mutate: mutateUpdate, isLoading: loadUpdate } =
    useUpdateUser(handleClose);

  useEffect(() => {
    const { isEdit = false, editData = {} } = editUserInstance;
    if (
      isEdit &&
      editData &&
      Object.keys(editData) &&
      Object.keys(editData).length > 0
    ) {
      reset(editData);
    }
  }, []);

  async function onSubmit(values) {
    const { isEdit = false, editData = {} } = editUserInstance;

    if (isEdit) {
      values.uuid = editData?.uuid;
      mutateUpdate(values);
    } else {
      mutateAdd(values);
    }
  }

  const selectedCountry = watch('country');

  const timezones = selectedCountry
    ? countries?.find((country) => country.isoCode === selectedCountry)
        ?.timezones
    : [];

  const loading = isSubmitting || loadAdd || loadUpdate;
  return (
    <form
      className="w--full d--flex flex--column gap--md"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <div className="overflow--auto dialogScroll w--full d--flex flex--column gap--md p--md">
        <div className="d--flex gap--md">
          <Controller
            name="document_name"
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                type="input"
                label="Document Name"
                placeholder="Enter document name"
                error={errors?.document_name?.message}
              />
            )}
          />
        </div>

        <div className="tw-flex tw-flex-row tw-items-center">
        <input
          type="file"
          id="custom-input"
          onChange={(e) => setSelectedFile(e.target.files[0].name)}
          hidden
        />
        <label
          htmlFor="custom-input"
          className="tw-block tw-text-md  tw-mr-4 tw-py-2 tw-px-4
            tw-rounded-md tw-border-0  tw-font-semibold tw-bg-pink-50
            tw-text-pink-700 hover:tw-bg-pink-100 tw-transition-colors tw-duration-100 tw-cursor-pointer"
        >
          Choose file
        </label>
        <label className="tw-text-sm tw-text-slate-500">{selectedFile}</label>
      </div>
      </div>


      <div className="d--flex justify-content--between gap--md p-l--md p-r--md">
        <Button
          type="button"
          variant="contrast"
          color="primary"
          btnClasses="btn"
          onClick={() => handleClose()}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          size=""
          variant="primary"
          color="white"
          btnClasses="btn"
          disabled={loading}
        >
          {loading ? 'Please wait..' : 'Save'}
        </Button>
      </div>
    </form>
  );
}
