import React from 'react';

const FormSelect = (
  {
    placeholder,
    label,
    error,
    extraClasses = '',
    options = [],
    renderOption: RenderOption,
    height = '36',
    defaultOption = true,
    ...rest
  },
  ref,
) => {
  const text = error || label || '';
  // console.log("options" , options)
  return (
    <div className="w--full  d--flex flex--column">
      {text && (
        <label
          className={`label--control font--sm font--500 m-b--xs  ${
            error ? 'text--danger' : 'text--black'
          }`}
        >
          {text}
        </label>
      )}

      <select
        {...rest}
        ref={ref}
        placeholder={placeholder}
        className={`form--control w--full h-min--${height}   radius--sm p-l--md p-r--md ${extraClasses} ${
          error ? 'border-full--danger' : 'border-full--black-200'
        }`}
      >
        {defaultOption && (
          <option value="" className="text--black">
            Select
          </option>
        )}
        {options &&
          options.length > 0 &&
          options.map((item) => <RenderOption key={item.value} item={item} />)}
      </select>
    </div>
  );
};

export default React.forwardRef(FormSelect);
