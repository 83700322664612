import React from 'react';
import TableManager from '../../../../components/TableManager';
import { getAgentCalls } from '../../../../api';
import { useParams } from 'react-router-dom';
import { durationToMMSS, formatDate } from '../../../../helpers/functions';

const Call = () => {
  const { id } = useParams();
  const columns = [
    {
      accessorKey: 'start_stamp',
      header: () => 'Date',
      cell: (props) => formatDate(props?.getValue()),
      meta: {
        textAlign: 'center',
      },
    },
    // {
    //   accessorKey: 'accountcode',
    //   header: () => 'Account No',
    //   cell: (props) => props?.row?.original?.user?.account_number,
    //   meta: {
    //     textAlign: 'center',
    //   },
    // },
    // {
    //   accessorKey: 'user.email',
    //   header: () => 'Email',
    // },
    {
      accessorKey: 'caller_id_number',
      header: () => 'From',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'destination_number',
      header: () => 'To',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'via_did',
      header: () => 'Via Virtual No',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'direction',
      header: () => 'Call Type',
    },
    {
      accessorKey: 'forward_type',
      header: () => 'Forwarding Type',
    },
    {
      accessorKey: 'duration',
      header: () => 'Duration',
      cell: (props) => durationToMMSS(props.getValue()),
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'charge',
      header: () => 'Charge($)',
      meta: {
        textAlign: 'right',
      },
    },
  ];
  return (
    <div className="w--full userDetailsCommon">
      <TableManager
        {...{
          name: 'Calls',
          columns,
          fetcherFn: getAgentCalls,
          fetcherKey: 'getAgentCalls',
          extraClasses: 'table--responsive--full',
          extraParams: {
            uuid: id,
          },
        }}
      />
    </div>
  );
};

export default Call;
