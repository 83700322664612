// import React, { useState } from 'react';
// import FormInput from '../FormInput';
// import Dropdown from '../Dropdown';
// import useIcons from '../../assets/icons/useIcons';

// const handleInitialValues = ({
//   arr = [],
//   optionList = '',
//   valueKey,
//   labelKey,
//   withPair,
//   withPairKeyName,
//   withPairValueName,
// }) => {
//   const newValues = [];
//   if (withPair) {
//     const optionArr =
//       optionList.map((option) => ({
//         [withPairKeyName]: option?.[valueKey],
//         [withPairValueName]: option?.[labelKey],
//       })) || [];
//     for (let index = 0; index < arr.length; index++) {
//       const element = arr[index];
//       if (
//         optionArr?.find(
//           (option) => option[withPairKeyName] === element[withPairKeyName],
//         )
//       ) {
//         newValues.push(element);
//       }
//     }
//   } else {
//     const optionArr = optionList.map((option) => option?.[valueKey]) || [];
//     for (let index = 0; index < arr.length; index++) {
//       const element = arr[index];
//       if (optionArr.includes(element)) {
//         newValues.push(element);
//       }
//     }
//   }
//   return newValues;
// };
// const MultiDropDown = ({
//   optionList = [],
//   labelKey = 'name',
//   valueKey = 'value',
//   label = '',
//   placeholder = '',
//   readOnly = false,
//   error = '',
//   withCaret = false,
//   withPair = false,
//   withPairKeyName = 'key',
//   withPairValueName = 'value',
//   ...rest
// }) => {
//   const { CaretIcon } = useIcons();
//   const [searchValue, setSearchValue] = useState('');
//   const updatedInitialValues = handleInitialValues({
//     arr: rest.value || [],
//     optionList,
//     valueKey,
//     labelKey,
//     withPair,
//     withPairKeyName,
//     withPairValueName,
//   });
//   const handleCheckBoxChange = (value, disableMe = false, label = '') => {
//     if (readOnly || disableMe) return;
//     if (withPair) {
//       if (
//         updatedInitialValues?.find(
//           (object) => object[withPairKeyName] === value,
//         )
//       ) {
//         const updatedValues = updatedInitialValues.filter((object) => {
//           if (object[withPairKeyName] !== value) {
//             return {
//               [withPairKeyName]: value,
//               [withPairValueName]: label,
//             };
//           }
//           return null;
//         });
//         rest?.onChange(updatedValues);
//       } else {
//         rest?.onChange([
//           ...updatedInitialValues,
//           { [withPairKeyName]: value, [withPairValueName]: label },
//         ]);
//       }
//       return;
//     }
//     if (updatedInitialValues?.includes(value)) {
//       const updatedValues = updatedInitialValues?.filter(
//         (val) => val !== value,
//       );
//       rest?.onChange(updatedValues);
//     } else {
//       rest?.onChange([...updatedInitialValues, value]);
//     }
//   };

//   const dropListNotification = {
//     component: ({ item }) => {
//       const disableMe = item?.disableMe ? item?.disableMe : false;
//       const fieldLabel = item?.[labelKey] || 'NA';
//       const fieldValue = item?.[valueKey] || 'NA';
//       let isChecked;
//       if (withPair) {
//         isChecked = updatedInitialValues?.find(
//           (object) => object[withPairKeyName] === fieldValue,
//         )
//           ? true
//           : false;
//       } else {
//         isChecked = updatedInitialValues?.includes(fieldValue) ?? false;
//       }

//       return (
//         <label
//           className="w--full d--flex gap--sm c--pointer align-items--center justify-content--start"
//           htmlFor={fieldLabel}
//           onClick={() =>
//             handleCheckBoxChange(fieldValue, disableMe, fieldLabel)
//           }
//         >
//           {!readOnly && (
//             <input
//               id={fieldLabel}
//               type="checkbox"
//               checked={isChecked}
//               disabled={readOnly || disableMe}
//             />
//           )}
//           {fieldLabel}{' '}
//           {disableMe ? (
//             <div className="bg--secondary radius--full p-l--sm  p-r--sm w-max--60 text--white d--flex justify-content--center align-items--center">
//               In use
//             </div>
//           ) : null}
//         </label>
//       );
//     },
//     data:
//       optionList.filter((option) =>
//         option?.[labelKey]?.toLowerCase().includes(searchValue),
//       ) || [],
//   };
//   return (
//     <div className="w--full  d--flex flex--column">
//       <Dropdown
//         closeOnClickOutside={false}
//         dropList={dropListNotification}
//         showcaret={withCaret}
//         caretComponent={CaretIcon}
//         extraClass="w--full"
//       >
//         <FormInput
//           {...rest}
//           type="text"
//           extraClass="w--full"
//           label={label}
//           placeholder={
//             placeholder ||
//             `${updatedInitialValues?.length ?? 0} values are selected.`
//           }
//           value={searchValue}
//           onChange={(e) => setSearchValue(e.target.value)}
//           error={error}
//         />
//       </Dropdown>
//     </div>
//   );
// };

// export default MultiDropDown;

import React, { useState } from 'react';
import FormInput from '../FormInput';
import Dropdown from '../Dropdown';
import useIcons from '../../assets/icons/useIcons';
import Avatar from '../Avatar';

const handleInitialValues = (arr = [], optionList = '', valueKey) => {
  const newValues = [];
  const optionArr = optionList.map((option) => option?.[valueKey]) || [];
  for (let index = 0; index < arr.length; index++) {
    const element = arr[index];
    if (optionArr.includes(element)) {
      newValues.push(element);
    }
  }
  return newValues;
};
const MultiDropDown = ({
  optionList = [],
  labelKey = 'name',
  valueKey = 'value',
  label = '',
  placeholder = '',
  readOnly = false,
  error = '',
  withCaret = false,
  border = true,
  extraClasses = '',
  paddingRight = 'md',
  paddingLeft = 'md',
  isMemberList = false,
  isSearchable = true,
  ...rest
}) => {
  const { CaretIcon } = useIcons();
  const [searchValue, setSearchValue] = useState('');
  const updatedInitialValues = handleInitialValues(
    rest.value,
    optionList,
    valueKey,
  );
  const handleCheckBoxChange = (value, disableMe = false) => {
    if (readOnly || disableMe) return;
    if (updatedInitialValues?.includes(value)) {
      const updatedValues = updatedInitialValues?.filter(
        (val) => val !== value,
      );
      rest?.onChange(updatedValues);
    } else {
      rest?.onChange([...updatedInitialValues, value]);
    }
  };

  const dropListNotification = {
    component: ({ item }) => {
      const disableMe = item?.disableMe ? item?.disableMe : false;
      const fieldLabel =
        labelKey instanceof Array
          ? labelKey.map((key) => item?.[key]).join(' ') || 'NA'
          : item?.[labelKey] || 'NA';
      const fieldValue = item?.[valueKey] || 'NA';
      const isChecked = updatedInitialValues?.includes(fieldValue) ?? false;
      return (
        <label
          className="w--full d--flex gap--sm c--pointer align-items--center justify-content--start p--sm"
          htmlFor={fieldLabel}
          onClick={() => handleCheckBoxChange(fieldValue, disableMe)}
        >
          {!readOnly && (
            <input
              id={fieldLabel}
              type="checkbox"
              checked={isChecked}
              disabled={readOnly || disableMe}
            />
          )}
          {isMemberList ? (
            <Avatar
              first_name={item?.first_name}
              last_name={item?.last_name}
              image={item?.profile_pic}
              size="28"
            />
          ) : null}
          <div className="d--flex flex--column">
            {fieldLabel}
            {isMemberList ? (
              <span className="font--xs font--400 text--grey">
                {item?.extension}
              </span>
            ) : null}
          </div>
          {disableMe ? (
            <div className="bg--secondary radius--full p-l--sm  p-r--sm w-max--60 text--white d--flex justify-content--center align-items--center">
              In use
            </div>
          ) : null}
        </label>
      );
    },
    data: searchValue
      ? optionList.filter((option) =>
          option?.[labelKey]?.toLowerCase().includes(searchValue),
        ) || []
      : optionList,
  };
  return (
    <div className="w--full  d--flex flex--column">
      <Dropdown
        closeOnClickOutside={false}
        dropList={dropListNotification}
        showcaret={withCaret}
        caretComponent={CaretIcon}
        extraClasses="w--full"
      >
        <FormInput
          type="text"
          extraClasses={`w--full ${extraClasses}`}
          label={label}
          placeholder={
            placeholder ||
            `${updatedInitialValues?.length ?? 0} values are selected.`
          }
          value={searchValue}
          onChange={(e) => isSearchable && setSearchValue(e.target.value)}
          error={error}
          border={border}
          paddingRight={paddingRight}
          paddingLeft={paddingLeft}
        />
      </Dropdown>
    </div>
  );
};

export default MultiDropDown;
