import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addCarrier } from '../../api';
import { useAlert } from '../useAlert';

export default function useAddCarrier(handleClose) {
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();
  const addCarrierRequest = useMutation({
    mutationFn: addCarrier,
    mutationKey: 'addCarrier',
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries('getCarrier');
      showAlert({
        type: 'success',
        message: data?.message ?? 'Carrier added sucessfully',
      });
      handleClose();
    },
    onError: (error) => {
      const errMsg = error?.message  ? error?.message : 'unexpected error';
    //   console.log("error: - ",error)
      showAlert({ type: 'secondary', message: errMsg });
    },
  });
  return { ...addCarrierRequest };
}
