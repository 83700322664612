import React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import useIcons from '../../assets/icons/useIcons';

export default function Sidebar({ userDetail = {} }) {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    GroupIcon,
    BackRoundIcon,
    ProfileIcon,
    BuildingIcon,
    HeadPhoneIcon,
    CallIcon,
    BillingIcon,
  } = useIcons();
  const tabs = [
    {
      id: 1,
      label: 'Profile',
      icon: ProfileIcon,
      path: 'profile',
    },
    {
      id: 2,
      label: 'Employees/Agents',
      icon: GroupIcon,
      path: 'member',
    },
    {
      id: 3,
      label: 'Agencies',
      icon: BuildingIcon,
      path: 'agency',
    },
    {
      id: 5,
      label: 'Virtual Number',
      icon: HeadPhoneIcon,
      path: 'did-number',
    },
    {
      id: 7,
      label: 'Calls',
      icon: CallIcon,
      path: 'call',
    },
    {
      id: 8,
      label: 'DID Billing',
      icon: BillingIcon,
      path: 'did-billing',
    },
    {
      id: 9,
      label: 'Plan Billing',
      icon: BillingIcon,
      path: 'plan-billing',
    },
  ];

  const handleActiveClass = (active) => {
    if (active === location.pathname.split('/').at(-1)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <aside className="d--flex w--full w-max--200 h--full bg--transparent radius--sm text--white p-t--sm p-r--md p-l--md p-b--md flex--column gap--sm">
      <div className="w--full d--flex  justify-content--start align-items--start font--sm font--600 text--primary m-b--md">
        <span className="c--pointer d--flex" onClick={() => navigate('/user')}>
          <BackRoundIcon />
        </span>
        <div className="d--flex flex--column m-l--sm align-items--center">
          <span className="font--md">{userDetail?.tenant_company}</span>
          <span className="font--xs">
            {userDetail?.first_name} {userDetail?.last_name}
          </span>
        </div>
      </div>
      <div className="d--flex flex--column gap--md w--full h--full">
        {tabs.map(({ id, label, icon: Icon, path }) => {
          return (
            <NavLink
              key={id}
              to={path}
              className={`${
                handleActiveClass(path)
                  ? 'bg--primary text--white'
                  : ' text--black'
              }  d--flex align-items--center justify-content--start  d--flex gap--sm p--sm radius--sm h-min--36`}
            >
              <Icon width={20} height={20} /> {label}
            </NavLink>
          );
        })}
      </div>
    </aside>
  );
}
