import React from 'react';
import TableManager from '../../../../components/TableManager';
import { getAgentDIDNumbers } from '../../../../api';
import { useParams } from 'react-router-dom';
const DIDNumber = () => {
  const { id = '' } = useParams();
  const columns = [
    {
      accessorKey: 'did_number',
      header: () => 'Number',
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'get_user.tenant_company',
      header: () => 'Tenant Company',
      cell: (props) => props?.getValue(),
      meta: {
        textAlign: 'left',
        width: '10%',
      },
    },
    {
      accessorKey: 'get_agency.name',
      header: () => 'Agency Company',
      cell: (props) => props?.getValue(),
      meta: {
        textAlign: 'left',
        width: '12%',
      },
    },
    {
      accessorKey: 'did_country',
      header: () => 'Country',
    },
    {
      accessorKey: 'buy_date',
      header: () => 'Buy Date',
    },
    {
      accessorKey: 'renewal_date',
      header: () => 'Renewal Date',
    },
    {
      accessorKey: 'monthly_cost',
      header: () => 'Cost',
      cell: (props) => {
        return <b>${props.getValue()}</b>;
      },
      meta: {
        textAlign: 'right',
      },
    },
    {
      accessorKey: 'status',
      header: () => 'Status',
      cell: (props) => {
        if (props.getValue() === 'Y') {
          return <span>Activated</span>;
        } else {
          return <span>Deactivated</span>;
        }
      },
      meta: {
        textAlign: 'center',
      },
    },
  ];
  return (
    <div className="w--full userDetailsCommon">
      <TableManager
        {...{
          name: 'Virtual Number',
          columns,
          fetcherFn: getAgentDIDNumbers,
          fetcherKey: 'getAgentDIDNumbers',
          extraClasses: 'table--responsive--full',
          extraParams: {
            uuid: id,
          },
        }}
      />
    </div>
  );
};

export default DIDNumber;
